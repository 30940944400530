import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ButtonColor } from '@widgets/eop-button/eop-button.component';
import { IconColor, IconSize, IconWeight } from '@widgets/eop-icon';

export enum VaylensButtonType {
  PRIMARY,
  SECONDARY,
}

@Component({
  selector: 'innogy-box-action',
  templateUrl: './box-action.component.html',
  styleUrls: ['./box-action.component.scss'],
})
export class BoxActionComponent {
  @Input()
  label: string;

  @Input()
  icon: string;

  @Input()
  vaylensFontIcon: boolean = false;

  @Input()
  iconText: string;

  @Input()
  rightIconText: boolean = false;

  @Input()
  disabled = false;

  @Input()
  isNavigational = false;

  @Input()
  invertColours = false;

  @HostBinding('class.is-highlighted')
  @Input()
  isHighlighted = false;

  @Input()
  vaylensButton = false;

  @Input()
  vaylensIconButton = false;

  @Input()
  vaylensIconColor: IconColor;

  @Input()
  vaylensButtonColor = ButtonColor.PRIMARY;

  @Input()
  vaylensTextButton = false;

  @Input()
  dataTestId: string = null;

  @Output()
  actionClicked = new EventEmitter<void>();

  readonly IconWeight = IconWeight;
  readonly IconSize = IconSize;
  readonly IconColor = IconColor;
}
